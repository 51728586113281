import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Optimizing React choropleth map rendering",
  "description": "",
  "date": "2016-12-19T08:00:00.000Z",
  "published": "2016-12-19T08:00:00.000Z",
  "image": ""
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`This is a Livecoding Recap – an almost-weekly post about interesting things discovered while livecoding ?. Always under 500 words and with pictures. You can follow my channel `}<a parentName="em" {...{
          "href": "https://www.youtube.com/user/TheSwizec/"
        }}>{`here`}</a>{`. New streams almost every Sunday at 2pm PDT. There’s live chat, come say hai ?`}</em></p>
    <p>{`Yesterday was supposed to be all about cutting up a large-ish codebase into small chunks that are easier to explain step-by-step. I was a great plan, but it didn't `}<em parentName="p">{`quite`}</em>{` happen.`}</p>
    <p>{`Instead of doing that, I went down the rabbit hole of optimizing the choropleth map from `}<a parentName="p" {...{
        "href": "https://swizec.com/blog/livecoding-24-choropleth-react-js/swizec/7078"
      }}>{`Livecoding 24`}</a>{`. Turns out my H1B visualization wasn't slow because the dataset was big. It was slow because the map re-renders 3,220 county polygons on every update. ?`}</p>
    <p>{`Let me explain.`}</p>
    <p>{`We started with this:`}</p>
    <p><img parentName="p" {...{
        "src": "http://swizec.com/blog/wp-content/uploads/2016/12/unoptimized-rendering.gif",
        "alt": "unoptimized-rendering"
      }}></img></p>
    <p>{`See how it takes 2 or 3 seconds to react when you click a button? That's bad.`}</p>
    <p>{`And we finished with this:`}</p>
    <p><img parentName="p" {...{
        "src": "http://swizec.com/blog/wp-content/uploads/2016/12/optimized-rendering.gif",
        "alt": "optimized-rendering"
      }}></img></p>
    <p>{`Takes just a second. Still slow, but `}<em parentName="p">{`a lot`}</em>{` better. The dataset to crunch is big after all. ?`}</p>
    <p>{`Unfortunately, the paint flashing isn't useful to show you what's fixed. Looks like a parent component repaints and flashes everything. This is unfortunate and a potential clue for further improvements.`}</p>
    <p>{`To make this 50% to 66% speed improvement, we did two things:`}</p>
    <ol>
      <li parentName="ol">{`Added `}<inlineCode parentName="li">{`shouldComponentUpdate`}</inlineCode>{` to the `}<inlineCode parentName="li">{`County`}</inlineCode>{` component`}</li>
      <li parentName="ol">{`Avoided doing `}<inlineCode parentName="li">{`_.find`}</inlineCode>{` to find data on every polygon draw`}</li>
    </ol>
    <p>{`I don't know which improvement had a bigger effect, but when you `}<inlineCode parentName="p">{`_.find`}</inlineCode>{` in a N=290 array 3220 times, that's 933,800 function calls that you're making. Oops.`}</p>
    <p>{`We fixed that by building a map beforehand. Like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const countyValueMap = _.fromPairs(
  this.props.values.map((d) => [d.countyID, d.value])
);
`}</code></pre>
    <p>{`Then rendering `}<inlineCode parentName="p">{`County`}</inlineCode>{` components in a loop, like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{counties.map((feature) => (

))}
`}</code></pre>
    <p>{`That `}<inlineCode parentName="p">{`value`}</inlineCode>{` prop used to be `}<inlineCode parentName="p">{`_.find(counties, {countyID: feature.id})`}</inlineCode>{` and that was dumb.`}</p>
    <p>{`Lesson learned. Big-O matters even when you're just doing JavaScript.`}</p>
    <p>{`Inside `}<inlineCode parentName="p">{`County`}</inlineCode>{`, we added `}<inlineCode parentName="p">{`shouldComponentUpdate`}</inlineCode>{`. I don't know `}<em parentName="p">{`why`}</em>{` React wasn't able to optimize this for us, but it wasn't. Maybe because we were passing props in as functions? Probably.`}</p>
    <p>{`The new `}<inlineCode parentName="p">{`County`}</inlineCode>{` component looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class County extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { zoom, value } = this.props;

    return zoom !== nextProps.zoom || value !== nextProps.value;
  }

  render() {
    // body from previous functional stateless version
  }
}
`}</code></pre>
    <p>{`We know for a fact that a county polygon only has to re-render when the map's zoom level changes or the value we're displaying changes. That enables the map to change size and the value to change polygon colors.`}</p>
    <p>{`Excellent. Many lessons learned.`}</p>
    <p>{`You can see the code for `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/h1b-software-salaries/tree/create-react-app/src/components/CountyMap"
      }}>{`React + D3 choropleth maps on Github`}</a>{`. It's embedded in a larger project, so there's some context-specific stuff, but the component itself is made to be pretty general.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      